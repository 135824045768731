<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Filters @filters="setFilters" />
    <Overview :filters="filters"></Overview>
    <div class="row">
      <div class="col">
        <HourTrend :filters="filters"></HourTrend>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <TopViewed :filters="filters"></TopViewed>
      </div>
      <div class="col">
        <TopSpecialities :filters="filters"></TopSpecialities>
      </div>
    </div>
    <!-- <div class="mx-auto" style="width: 13vw">
      <b-button-group size="lg mb-2">
        <b-button
          v-for="(btn, idx) in buttons"
          :key="idx"
          :pressed.sync="btn.state"
          variant="primary"
          @click.prevent="btnClick(btn.id)"
        >
          {{ btn.caption }}
        </b-button>
      </b-button-group>
    </div> -->
    <!-- <template v-if="this.buttons[0].state">
      <div class="col-lg-6">
        <Overview :filters="filters" :toggle="toggle"></Overview>
      </div>
    </template>
    <template v-if="this.buttons[1].state">
      <div class="col-lg-6">
        <Overview :filters="filters" :toggle="toggle"></Overview>
      </div>
    </template> -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Filters from "./filters.vue";
import Overview from "./Overview.vue";
import TopViewed from "./TopViewed.vue";
import TopSpecialities from "./TopSpecialities.vue";
import HourTrend from './hourTrend.vue'

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Filters,
    Overview,
    TopViewed,
    TopSpecialities,
    HourTrend
},
  data() {
    return {
      buttons: [
        { caption: "MCQ's", state: true, id: "btn1" },
        { caption: "Posts", state: false, id: "btn2" },
      ],
      title: "Cases",
      items: [
        {
          text: "Medisage",
        },
        {
          text: "Cases",
          active: true,
        },
      ],
      toggle: "btn1",
      summary: {},
      previousSummary: {},
      state_summary: {},
      filters: {},
      reload: 0,
      params: {},
      loading: true,
    };
  },
  created() {
    // this.init();
  },
  methods: {
    btnClick(click_id) {
      this.buttons.map((btn) => {
        if (btn.id == click_id) {
          btn.state = true;
          this.toggle = btn.id;
        } else {
          btn.state = false;
        }
      });
    },
    init() {
      // this.loading = true;
      // this.loading = false;
    },
    setFilters(v) {
      this.filters = v;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        // this.init();
      },
    },
  },
};
</script>
