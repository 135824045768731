<template>
  <!-- <div> -->
    <div class="row">
      <div class="col-md-3">
        <div class="card grid-card">
          <div class="card-body">
            <div class="mb-2">
              <h4 class="card-title">Total Posts Added</h4>
              <h3 class="mb-0">{{ count.posts_published }}</h3>
            </div>
            <template v-if="statsData[0].subvalue">
              <div class="">
                <div
                  class="badge font-size-14"
                  :class="
                    statsData[0].subvalue > 0
                      ? 'badge-soft-success'
                      : 'badge-soft-danger'
                  "
                >
                  {{ statsData[0].subvalue }} %
                </div>
                <div class="text-muted">{{ statsData[0].subtext }}</div>
              </div>
            </template>
          </div>
          <div class="card-body border-left">
            <div class="mb-2">
              <h4 class="card-title">Total MCQ's Added</h4>
              <h3 class="mb-0">{{ count.mcqs_published }}</h3>
            </div>
            <template v-if="statsData[1].subvalue">
              <div class="">
                <div
                  class="badge font-size-14"
                  :class="
                    statsData[1].subvalue > 0
                      ? 'badge-soft-success'
                      : 'badge-soft-danger'
                  "
                >
                  {{ statsData[1].subvalue }} %
                </div>
                <div class="text-muted">{{ statsData[1].subtext }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card grid-card">
          <div class="card-body">
            <div class="mb-2">
              <h4 class="card-title">Total Posts Views</h4>
              <h3 class="mb-0">{{ (typeof count.total_views_and_viewers_by_type[1] != 'undefined' ? count.total_views_and_viewers_by_type[1].views : 0) }}</h3>
              <!-- <h3 class="mb-0">{{ count.total_views_and_viewers_by_type[1].views }}</h3> -->
            </div>
            <template v-if="statsData[2].subvalue">
              <div class="">
                <div
                  class="badge font-size-14"
                  :class="
                    statsData[2].subvalue > 0
                      ? 'badge-soft-success'
                      : 'badge-soft-danger'
                  "
                >
                  {{ statsData[2].subvalue }} %
                </div>
                <div class="text-muted">{{ statsData[2].subtext }}</div>
              </div>
            </template>
          </div>
          <div class="card-body border-left">
            <div class="mb-2">
              <h4 class="card-title">Total Posts Viewers </h4>
              <h3 class="mb-0">{{ (typeof count.total_views_and_viewers_by_type[1] != 'undefined' ? count.total_views_and_viewers_by_type[1].viewers : 0) }}</h3>
              <!-- <h3 class="mb-0">{{ count.total_views_and_viewers_by_type[1].viewers }}</h3> -->
            </div>
            <template v-if="statsData[3].subvalue">
              <div class="">
                <div
                  class="badge font-size-14"
                  :class="
                    statsData[3].subvalue > 0
                      ? 'badge-soft-success'
                      : 'badge-soft-danger'
                  "
                >
                  {{ statsData[3].subvalue }} %
                </div>
                <div class="text-muted">{{ statsData[3].subtext }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card grid-card">
          <div class="card-body">
            <div class="mb-2">
              <h4 class="card-title">Total MCQ's Views</h4>
              <h3 class="mb-0">{{ count.total_views_and_viewers_by_type[0].views }}</h3>
            </div>
            <template v-if="statsData[4].subvalue">
              <div class="">
                <div
                  class="badge font-size-14"
                  :class="
                    statsData[4].subvalue > 0
                      ? 'badge-soft-success'
                      : 'badge-soft-danger'
                  "
                >
                  {{ statsData[4].subvalue }} %
                </div>
                <div class="text-muted">{{ statsData[4].subtext }}</div>
              </div>
            </template>
          </div>
          <div class="card-body border-left">
            <div class="mb-2">
              <h4 class="card-title">Total MCQ's Viewers </h4>
              <!-- <h3 class="mb-0">{{ (typeof count.total_views_and_viewers_by_type[1] != 'undefined' ? count.total_views_and_viewers_by_type[1].viewers : 0) }}</h3> -->
              <h3 class="mb-0">{{ count.total_views_and_viewers_by_type[0].viewers }}</h3>
            </div>
            <template v-if="statsData[5].subvalue">
              <div class="">
                <div
                  class="badge font-size-14"
                  :class="
                    statsData[5].subvalue > 0
                      ? 'badge-soft-success'
                      : 'badge-soft-danger'
                  "
                >
                  {{ statsData[5].subvalue }} %
                </div>
                <div class="text-muted">{{ statsData[5].subtext }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card grid-card">
          <div class="card-body">
            <div class="mb-2">
              <h4 class="card-title">Total Views</h4>
              <h3 class="mb-0">{{ count.total_views_and_viewers[0].views }}</h3>
            </div>
            <!-- <template v-if="statsData[4].subvalue">
              <div class="">
                <div
                  class="badge font-size-14"
                  :class="
                    statsData[4].subvalue > 0
                      ? 'badge-soft-success'
                      : 'badge-soft-danger'
                  "
                >
                  {{ statsData[4].subvalue }} %
                </div>
                <div class="text-muted">{{ statsData[4].subtext }}</div>
              </div>
            </template> -->
          </div>
          <div class="card-body border-left">
            <div class="mb-2">
              <h4 class="card-title">Total Viewers</h4>
              <h3 class="mb-0">{{ count.total_views_and_viewers[0].viewers }}</h3>
            </div>
            <!-- <template v-if="statsData[5].subvalue">
              <div class="">
                <div
                  class="badge font-size-14"
                  :class="
                    statsData[5].subvalue > 0
                      ? 'badge-soft-success'
                      : 'badge-soft-danger'
                  "
                >
                  {{ statsData[5].subvalue }} %
                </div>
                <div class="text-muted">{{ statsData[5].subtext }}</div>
              </div>
            </template> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="this.toggle === 'btn1'">
      <div class="card">
        <div class="card-body pb-0 pt-2">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="card-title">MCQ Overview</h4>
            <div class="d-flex overall-count pb-2">
              <div class="mr-2">Overall Count</div>
              <Switches
                v-model="overallCount"
                type-bold="true"
                color="success"
                class="m-0"
              ></Switches>
            </div>
          </div>
        </div>
        <div class="row border-bottom">
          <div class="col">
            <div>
              <div class="card-body pb-0 pt-2">
                <h4 class="card-title">MCQ's Published</h4>
                <h3>{{ count.mcqs_published }}</h3>
              </div>
            </div>
          </div>
          <div class="col">
            <div>
              <div class="card-body pb-0 pt-2">
                <h4 class="card-title">MCQ Views</h4>
                <h3>{{ count.total_views_and_viewers[0].views }}</h3>
              </div>
              <div class="card-body border-top py-2">
                <div class="">
                  <template v-if="statsData[0].subvalue">
                    <span
                      class="badge font-size-11"
                      :class="
                        statsData[0].subvalue > 0
                          ? 'badge-soft-success'
                          : 'badge-soft-danger'
                      "
                    >
                      <i
                        class="mdi"
                        :class="
                          statsData[0].subvalue > 0
                            ? 'mdi-menu-up'
                            : 'mdi-menu-down'
                        "
                      ></i>
                      {{ statsData[0].subvalue }} %
                    </span>
                    <span class="text-muted ml-2">{{
                      statsData[0].subtext
                    }}</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row border-bottom">
          <div class="col">
            <div>
              <div class="card-body pb-0 pt-2">
                <h4 class="card-title">MCQ Viewers</h4>
                <h3>{{ count.total_views_and_viewers[0].viewers }}</h3>
              </div>
              <div class="card-body border-top py-2">
                <div class="">
                  <template v-if="statsData[1].subvalue">
                    <span
                      class="badge font-size-11"
                      :class="
                        statsData[1].subvalue > 0
                          ? 'badge-soft-success'
                          : 'badge-soft-danger'
                      "
                    >
                      <i
                        class="mdi"
                        :class="
                          statsData[1].subvalue > 0
                            ? 'mdi-menu-up'
                            : 'mdi-menu-down'
                        "
                      ></i>
                      {{ statsData[1].subvalue }} %
                    </span>
                    <span class="text-muted ml-2">{{
                      statsData[1].subtext
                    }}</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div>
              <div class="card-body pb-0 pt-2">
                <h4 class="card-title">MCQ Useful</h4>
                <h3>{{ count.total_views_and_viewers[0].useful }}</h3>
              </div>
              <div class="card-body border-top py-2">
                <div class="">
                  <template v-if="statsData[2].subvalue">
                    <span
                      class="badge font-size-11"
                      :class="
                        statsData[2].subvalue > 0
                          ? 'badge-soft-success'
                          : 'badge-soft-danger'
                      "
                    >
                      <i
                        class="mdi"
                        :class="
                          statsData[2].subvalue > 0
                            ? 'mdi-menu-up'
                            : 'mdi-menu-down'
                        "
                      ></i>
                      {{ statsData[2].subvalue }} %
                    </span>
                    <span class="text-muted ml-2">{{
                      statsData[2].subtext
                    }}</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div>
              <div class="card-body pb-0 pt-2">
                <h4 class="card-title">MCQ Answered</h4>
                <h3>{{ count.total_views_and_viewers[0].answered }}</h3>
              </div>
              <div class="card-body border-top py-2">
                <div class="">
                  <template v-if="statsData[3].subvalue">
                    <span
                      class="badge font-size-11"
                      :class="
                        statsData[3].subvalue > 0
                          ? 'badge-soft-success'
                          : 'badge-soft-danger'
                      "
                    >
                      <i
                        class="mdi"
                        :class="
                          statsData[3].subvalue > 0
                            ? 'mdi-menu-up'
                            : 'mdi-menu-down'
                        "
                      ></i>
                      {{ statsData[3].subvalue }} %
                    </span>
                    <span class="text-muted ml-2">{{
                      statsData[3].subtext
                    }}</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div>
              <div class="card-body pb-0 pt-2">
                <h4 class="card-title">MCQ Correctly Answered</h4>
                <h3>
                  {{ count.total_views_and_viewers[0].total_correct_answers }}
                </h3>
              </div>
              <div class="card-body border-top py-2">
                <div class="">
                  <template v-if="statsData[4].subvalue">
                    <span
                      class="badge font-size-11"
                      :class="
                        statsData[4].subvalue > 0
                          ? 'badge-soft-success'
                          : 'badge-soft-danger'
                      "
                    >
                      <i
                        class="mdi"
                        :class="
                          statsData[4].subvalue > 0
                            ? 'mdi-menu-up'
                            : 'mdi-menu-down'
                        "
                      ></i>
                      {{ statsData[4].subvalue }} %
                    </span>
                    <span class="text-muted ml-2">{{
                      statsData[4].subtext
                    }}</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.toggle === 'btn2'">
      <div class="card">
        <div class="card-body pb-0 pt-2">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="card-title">Post's Overview</h4>
            <div class="d-flex overall-count pb-2">
              <div class="mr-2">Overall Count</div>
              <Switches
                v-model="overallCount"
                type-bold="true"
                color="success"
                class="m-0"
              ></Switches>
            </div>
          </div>
        </div>
        <div class="row border-bottom">
          <div class="col">
            <div>
              <div class="card-body pb-0 pt-2">
                <h4 class="card-title">Post's Published</h4>
                <h3>{{ count.mcqs_published }}</h3>
              </div>
            </div>
          </div>
          <div class="col">
            <div>
              <div class="card-body pb-0 pt-2">
                <h4 class="card-title">Post Views</h4>
                <h3>{{ count.total_views_and_viewers[0].views }}</h3>
              </div>
              <div class="card-body border-top py-2">
                <div class="">
                  <template v-if="statsData[0].subvalue">
                    <span
                      class="badge font-size-11"
                      :class="
                        statsData[0].subvalue > 0
                          ? 'badge-soft-success'
                          : 'badge-soft-danger'
                      "
                    >
                      <i
                        class="mdi"
                        :class="
                          statsData[0].subvalue > 0
                            ? 'mdi-menu-up'
                            : 'mdi-menu-down'
                        "
                      ></i>
                      {{ statsData[0].subvalue }} %
                    </span>
                    <span class="text-muted ml-2">{{
                      statsData[0].subtext
                    }}</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row border-bottom">
          <div class="col">
            <div>
              <div class="card-body pb-0 pt-2">
                <h4 class="card-title">Post Viewers</h4>
                <h3>{{ count.total_views_and_viewers[0].viewers }}</h3>
              </div>
              <div class="card-body border-top py-2">
                <div class="">
                  <template v-if="statsData[1].subvalue">
                    <span
                      class="badge font-size-11"
                      :class="
                        statsData[1].subvalue > 0
                          ? 'badge-soft-success'
                          : 'badge-soft-danger'
                      "
                    >
                      <i
                        class="mdi"
                        :class="
                          statsData[1].subvalue > 0
                            ? 'mdi-menu-up'
                            : 'mdi-menu-down'
                        "
                      ></i>
                      {{ statsData[1].subvalue }} %
                    </span>
                    <span class="text-muted ml-2">{{
                      statsData[1].subtext
                    }}</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div>
              <div class="card-body pb-0 pt-2">
                <h4 class="card-title">Post Useful</h4>
                <h3>{{ count.total_views_and_viewers[0].useful }}</h3>
              </div>
              <div class="card-body border-top py-2">
                <div class="">
                  <template v-if="statsData[2].subvalue">
                    <span
                      class="badge font-size-11"
                      :class="
                        statsData[2].subvalue > 0
                          ? 'badge-soft-success'
                          : 'badge-soft-danger'
                      "
                    >
                      <i
                        class="mdi"
                        :class="
                          statsData[2].subvalue > 0
                            ? 'mdi-menu-up'
                            : 'mdi-menu-down'
                        "
                      ></i>
                      {{ statsData[2].subvalue }} %
                    </span>
                    <span class="text-muted ml-2">{{
                      statsData[2].subtext
                    }}</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div>
              <div class="card-body pb-0 pt-2">
                <h4 class="card-title">Post Comments</h4>
                <h3>{{ count.total_views_and_viewers[0].answered }}</h3>
              </div>
              <div class="card-body border-top py-2">
                <div class="">
                  <template v-if="statsData[3].subvalue">
                    <span
                      class="badge font-size-11"
                      :class="
                        statsData[3].subvalue > 0
                          ? 'badge-soft-success'
                          : 'badge-soft-danger'
                      "
                    >
                      <i
                        class="mdi"
                        :class="
                          statsData[3].subvalue > 0
                            ? 'mdi-menu-up'
                            : 'mdi-menu-down'
                        "
                      ></i>
                      {{ statsData[3].subvalue }} %
                    </span>
                    <span class="text-muted ml-2">{{
                      statsData[3].subtext
                    }}</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div>
              <div class="card-body pb-0 pt-2">
                <h4 class="card-title">Posts Correctly Answered</h4>
                <h3>
                  {{ count.total_views_and_viewers[0].total_correct_answers }}
                </h3>
              </div>
              <div class="card-body border-top py-2">
                <div class="">
                  <template v-if="statsData[4].subvalue">
                    <span
                      class="badge font-size-11"
                      :class="
                        statsData[4].subvalue > 0
                          ? 'badge-soft-success'
                          : 'badge-soft-danger'
                      "
                    >
                      <i
                        class="mdi"
                        :class="
                          statsData[4].subvalue > 0
                            ? 'mdi-menu-up'
                            : 'mdi-menu-down'
                        "
                      ></i>
                      {{ statsData[4].subvalue }} %
                    </span>
                    <span class="text-muted ml-2">{{
                      statsData[4].subtext
                    }}</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  <!-- </div> -->
</template>
<style scoped>
.grid-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
<script>
import appConfig from "@/app.config";
import filterMixins from "../../../mixins/filterData";
// import Switches from "vue-switches";

/**
 * Dashboard component
 */
export default {
  components: {
    // Switches,
  },
  props: ["filters", "toggle"],
  mixins: [filterMixins],
  data() {
    return {
      key: 0,

      count: {
        mcqs_published: 0,
        posts_published: 0,
        total_views_and_viewers_by_type : [
          {
            views : 0,
            viewers : 0,
          },
          {
            views : 0,
            viewers : 0,
          }
        ],
        total_views_and_viewers : [
          {
            views : 0,
            viewers : 0,
          },
          {
            views : 0,
            viewers : 0,
          }
        ]
      },
      statsData: [
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
      ],
      overallCount: false,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        // console.log('comming..');
        // console.log(this.filters);
        this.getCount();
      },
    },
    overallCount() {
      this.getCount();
    },
  },
  methods: {
    async getCount() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        if (this.overallCount == true) {
          const response = await this.$http.get(
            appConfig.api_base_url + "/cases/count?&overall=true",
            {
              params: searchfiltersParams,
            }
          );
          this.count = response.data;
        } else {
          const response = await this.$http.get(
            appConfig.api_base_url + "/cases/count",
            {
              params: searchfiltersParams,
            }
          );
          this.count = response.data;
        }
        // Total Posts Start
        this.statsData[0].subvalue =
          this.count.previous_period_posts_published !== 0
            ? (
                ((this.count.posts_published -
                  this.count.previous_period_posts_published) /
                  this.count.previous_period_posts_published) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[0].subvalue = !isNaN(this.statsData[0].subvalue)
          ? this.statsData[0].subvalue
          : "0";

        this.count.posts_published = !isNaN(
          this.count.posts_published
        )
          ? this.count.posts_published
          : "0";
        // Total Posts End

        // Total Quizes Start
        this.statsData[1].subvalue =
          this.count.previous_period_mcqs_published !== 0
            ? (
                ((this.count.mcqs_published -
                  this.count.previous_period_mcqs_published) /
                  this.count.previous_period_mcqs_published) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[1].subvalue = !isNaN(this.statsData[1].subvalue)
          ? this.statsData[1].subvalue
          : "0";

        this.count.mcqs_published = !isNaN(
          this.count.mcqs_published
        )
          ? this.count.mcqs_published
          : "0";
        // Total Quizes End 

        // Total Posts Views Start
        this.statsData[2].subvalue =
          this.count.previous_period_views_and_viewers_by_type[1].views !== 0
            ? (
                ((this.count.total_views_and_viewers_by_type[1].views -
                  this.count.previous_period_views_and_viewers_by_type[1].views) /
                  this.count.previous_period_views_and_viewers_by_type[1].views) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[2].subvalue = !isNaN(this.statsData[2].subvalue)
          ? this.statsData[2].subvalue
          : "0";

        this.count.total_views_and_viewers_by_type[1].views = !isNaN(
          this.count.total_views_and_viewers_by_type[1].views
        )
          ? this.count.total_views_and_viewers_by_type[1].views
          : "0";
        // Total Posts Views End

        // Total Posts Viewers Start
        this.statsData[3].subvalue =
          this.count.previous_period_views_and_viewers_by_type[1].viewers !== 0
            ? (
                ((this.count.total_views_and_viewers_by_type[1].viewers -
                  this.count.previous_period_views_and_viewers_by_type[1].viewers) /
                  this.count.previous_period_views_and_viewers_by_type[1].viewers) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[3].subvalue = !isNaN(this.statsData[3].subvalue)
          ? this.statsData[3].subvalue
          : "0";

        this.count.total_views_and_viewers_by_type[1].viewers = !isNaN(
          this.count.total_views_and_viewers_by_type[1].viewers
        )
          ? this.count.total_views_and_viewers_by_type[1].viewers
          : "0";
        // Total Posts Viewers End

        // Total Quiz Views Start
        this.statsData[4].subvalue =
          this.count.previous_period_views_and_viewers_by_type[0].views !== 0
            ? (
                ((this.count.total_views_and_viewers_by_type[0].views -
                  this.count.previous_period_views_and_viewers_by_type[0].views) /
                  this.count.previous_period_views_and_viewers_by_type[0].views) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[4].subvalue = !isNaN(this.statsData[4].subvalue)
          ? this.statsData[4].subvalue
          : "0";

        this.count.total_views_and_viewers[0].views = !isNaN(
          this.count.total_views_and_viewers[0].views
        )
          ? this.count.total_views_and_viewers[0].views
          : "0";
        // Total Quiz Views End

        // Total Quiz Viewers Start
        this.statsData[5].subvalue =
          this.count.previous_period_views_and_viewers_by_type[0].viewers !== 0
            ? (
                ((this.count.total_views_and_viewers[0].viewers -
                  this.count.previous_period_views_and_viewers_by_type[0].viewers) /
                  this.count.previous_period_views_and_viewers_by_type[0].viewers) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[5].subvalue = !isNaN(this.statsData[5].subvalue)
          ? this.statsData[5].subvalue
          : "0";

        this.count.total_views_and_viewers[0].viewers = !isNaN(
          this.count.total_views_and_viewers[0].viewers
        )
          ? this.count.total_views_and_viewers[0].viewers
          : "0";
        // Total Quiz Viewers End
        
        // this.statsData[2].subvalue =
        //   parseInt(this.count.previous_period_views_and_viewers_by_type[0].useful) !== 0
        //     ? (
        //         ((parseInt(this.count.total_views_and_viewers[0].useful) -
        //           parseInt(
        //             this.count.previous_period_views_and_viewers_by_type[0].useful
        //           )) /
        //           parseInt(
        //             this.count.previous_period_views_and_viewers_by_type[0].useful
        //           )) *
        //         100
        //       ).toFixed(2)
        //     : "0";
        // this.statsData[2].subvalue = !isNaN(this.statsData[2].subvalue)
        //   ? this.statsData[2].subvalue
        //   : "0";

        // this.count.total_views_and_viewers[0].useful = !isNaN(
        //   parseInt(this.count.total_views_and_viewers[0].useful)
        // )
        //   ? parseInt(this.count.total_views_and_viewers[0].useful)
        //   : "0";

        // this.statsData[3].subvalue =
        //   parseInt(this.count.previous_period_views_and_viewers_by_type[0].answered) !==
        //   0
        //     ? (
        //         ((parseInt(this.count.total_views_and_viewers[0].answered) -
        //           parseInt(
        //             this.count.previous_period_views_and_viewers_by_type[0].answered
        //           )) /
        //           parseInt(
        //             this.count.previous_period_views_and_viewers_by_type[0].answered
        //           )) *
        //         100
        //       ).toFixed(2)
        //     : "0";
        // this.statsData[3].subvalue = !isNaN(this.statsData[3].subvalue)
        //   ? this.statsData[3].subvalue
        //   : "0";

        // this.count.total_views_and_viewers[0].answered = !isNaN(
        //   parseInt(this.count.total_views_and_viewers[0].answered)
        // )
        //   ? parseInt(this.count.total_views_and_viewers[0].answered)
        //   : "0";

        // this.statsData[4].subvalue =
        //   parseInt(
        //     this.count.previous_period_views_and_viewers_by_type[0]
        //       .total_correct_answers
        //   ) !== 0
        //     ? (
        //         ((parseInt(
        //           this.count.total_views_and_viewers[0].total_correct_answers
        //         ) -
        //           parseInt(
        //             this.count.previous_period_views_and_viewers_by_type[0]
        //               .total_correct_answers
        //           )) /
        //           parseInt(
        //             this.count.previous_period_views_and_viewers_by_type[0]
        //               .total_correct_answers
        //           )) *
        //         100
        //       ).toFixed(2)
        //     : "0";
        // this.statsData[4].subvalue = !isNaN(this.statsData[4].subvalue)
        //   ? this.statsData[4].subvalue
        //   : "0";

        // this.count.total_views_and_viewers[0].total_correct_answers = !isNaN(
        //   parseInt(this.count.total_views_and_viewers[0].total_correct_answers)
        // )
        //   ? parseInt(
        //       this.count.total_views_and_viewers[0].total_correct_answers
        //     )
        //   : "0";
      } catch (err) {
        console.log(err);
      }
      this.key += 1;
    },
  },
  mounted() {
    this.getCount();
  },
};
</script>
